var site = site || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue = site.userInfoCookie.getValue || function () {
  return '';
};
(function ($) {
  Drupal.behaviors.utilityMenuV1 = {
    attach: function (context) {
      var $langContainer = $('.js-language-container', context);
      var $mobileBackButton = $('.js-mobile-checkout-back', context);

      if ($langContainer.length) {
        if ($langContainer.children('.js-lang-link1').length) {
          $langContainer.children('.js-lang-link1').attr('href', $langContainer.data('link1-url'));
        }
        if ($langContainer.children('.js-lang-link2').length) {
          $langContainer.children('.js-lang-link2').attr('href', $langContainer.data('link2-url'));
        }
      }
      $mobileBackButton.once().click(function (e) {
        e.preventDefault();
        history.go(-1);
      });
    }
  };
})(jQuery);
